import Swiper from '@iris.interactive/handcook/public/scripts/libraries/swiper-11'

export default class HeaderComponent {
    constructor() {
        HeaderComponent.swiper_banner()
        HeaderComponent.sticky()
        HeaderComponent.burger()
        HeaderComponent.search()
        HeaderComponent.translate()
    }

    static async swiper_banner() {
        const swiperItem = '[data-swiper="banner"]'
        const slideItem = '[data-swiper="banner"] > div'

        const options = {
            slidesPerView: 'auto',
            autoplay: {
                delay: 5000,
            },
            speed: 1500,
            effect: 'fade',
            fadeEffect: {
                crossFade: true,
            },
            pagination: {
                el: '#swiper-banner-pagination',
                type: 'bullets',
                clickable: true,
            },
            navigation: {
                nextEl: '#swiper-banner-next',
                prevEl: '#swiper-banner-prev',
            },
            mousewheel: {
                forceToAxis: true,
            },
            grabCursor: true,
            loop: true,
        }

        if ($(slideItem).length > 1) {
            $(swiperItem).append(
                '<span id="swiper-banner-pagination" class="hc-slider-pagination header__banner__pagination"></span>',
            )
            $(slideItem).wrapAll('<div class="swiper-wrapper"></div>').addClass('swiper-slide')
            await Swiper.create(swiperItem, options)
        }
    }

    static sticky() {
        let lastScrollTop = 0

        $(window).on('load scroll', function () {
            const offset = 140
            const offsetOnScroll = 100
            const navbar = $('[data-id="wpiris-navbar"]')
            const scrollTop = $(this).scrollTop()
            let full = false

            //Hidden comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $('body').addClass('header-is-hidden')
            } else {
                $('body').removeClass('header-is-hidden')
            }

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                full = true
            }

            lastScrollTop = scrollTop

            if (scrollTop > offset) {
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                if (full === true) {
                    HeaderComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll) {
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                if (full === true) {
                    HeaderComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $('[data-id="wpiris-logo"]')
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        const body = $('body')
        const trigger = '[data-trigger="wpiris-menu-toggle"]'
        const more = '[data-trigger="wpiris-menu-more"]'
        const prev = '[data-trigger="wpiris-menu-prev"]'
        const submenu = '[data-trigger="wpiris-menu-submenu"]'

        //Open/Close menu
        $(trigger).on('click', () => {
            body.toggleClass('menu-open')
        })

        //Open/Close submenu
        $(more).on('click', function () {
            $(this).next(submenu).toggleClass('submenu-open')
        })

        $(prev).on('click', (e) => {
            e.preventDefault()
            $(submenu).removeClass('submenu-open')
        })
    }

    static translate() {
        const trigger = '[data-trigger="glt-selector"]'
        const $element = $('[data-id="glt-selector"]')

        $(trigger).on('click', (e) => {
            e.preventDefault()
            $element.fadeIn(200)
        })

        $element.on('click', function (e) {
            if (!$(event.target).closest('.gltSelectorInner').length) {
                $(this).fadeOut(200)
            }
        })

        const updateCurrentLanguageToolbar = () => {
            let current_language = $('html').attr('lang')

            if (current_language === 'auto') {
                current_language = 'fr'
            }

            $(trigger).text(current_language !== 'false' ? current_language.slice(0, 2) : 'fr')
        }

        updateCurrentLanguageToolbar()
    }

    static search() {
        const trigger = '[data-id="wpiris-search-input"]'

        $(trigger).on('focus', (e) => {
            e.preventDefault()
            $('body').addClass('search-open')
        })

        $(trigger).on('focusout', (e) => {
            e.preventDefault()
            $('body').removeClass('search-open')
        })
    }
}
